var WOVGprofileNumber = "UA-2063136-9"; /* DO NOT MODIFY */
var DEPTprofileNumber = "UA-27023349-2"; /* Set Department/Agency roll up profile number - if none exists, replace with "UA-000000-0". */
var SITEprofileNumber = "UA-119703719-1";
var SITEGoogleTagManagerNumber = "UA-119703719-1"; /* Vhims google tag manager specific number */ 

/* Set tracking code values */
var dept = "dhhs"; /* Set Department/Agency achronym/name. */
var site = "vhimscentral.vic.gov.au"; /* Set website's domain location - do NOT include "www.", unless the site is located ENTIRELY on "www.". Likewise, if the website is located ENTIRELY on a subdomain, insert it, e.g. "subdomain.website.vic.gov.au". */
var siteOnSubFolder = "yes"; /* Set this to "yes" if the website is located ENTIRELY on a dedicated subfolder, e.g "website.vic.gov.au/subfolder", otherwise, leave as "no". */
var subFolder = "/uniquesigf43b46b996f751f8cef47f87dd4646fd/uniquesig0/ClientWeb/"; /* Set the subfolder value only if the site is located ENTIRELY on a dedicated subfolder (include the leading forwardslash "/"). */
var cookiePath = subFolder;


(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments)
    }, i[r].l = 1 * new Date(); a = s.createElement(o),
    m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
ga('create', WOVGprofileNumber, { 'name': 'WOVGprofileNumber' });
ga('create', DEPTprofileNumber, { 'name': 'DEPTprofileNumber' });
ga('create', SITEprofileNumber, { 'name': 'SITEprofileNumber' });


(function (d, script) {
    script = d.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = function () {
        // remote script has loaded
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', SITEGoogleTagManagerNumber);
    };
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + SITEGoogleTagManagerNumber;
    d.getElementsByTagName('head')[0].appendChild(script);
}(document));

//add ga to the footer links
jQuery('.footer__list_link').click(function () {
    var name = $(this).text();
    var href = $(this)[0].href;
    ga('SITEprofileNumber.send', 'event', 'click', name,
    {
        eventCategory: 'Footer: Outbound Link-' + href,
        eventAction: 'Click',
        eventLabel: href,       
        transport: 'beacon'
    });
});

